import { useState, useEffect } from 'react'

import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MDAvatar from 'components/MDAvatar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Tooltip from '@mui/material/Tooltip'

import Form from 'components/Form'
import RoomBeds from 'components/RoomBeds'
import getRoomSchema from './room-schema'
import { excerptHtml } from 'utils/functions'
import { RoomSkeleton } from './Skeletons'

import platinum from 'assets/icons/platinum.svg'
import gold from 'assets/icons/gold.svg'
import silver from 'assets/icons/silver.svg'
import bronze from 'assets/icons/bronze.svg'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '5px',
  right: '5px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}


const RoomCard = ({
  id,
  full,
  detail,
  accommodation,
  canRemove = false,
  remainingParticipants,
  participants,
  isLoading,
  onRandomFill,
  onChange,
  onDone,
  onRemove,
  onClean,
}) => {
  const getInitialValues = () => {
    return participants && !!participants.length ? participants
    .filter(({ room }) => room === id)
    .reduce((acc, curr, index) => {
      acc[`room-${id}-participant-${index}`] = curr.id || null
      return acc
    }, {}) : {}
  }
  const initialValues = getInitialValues()
  const [availableParticipants, setAvailableParticipants] = useState(
    remainingParticipants
  )
  const [seeAllDescription, setSeeAllDescription] = useState(false)
  const { title, capacity, category, description, level } = detail

  const [schema, setSchema] = useState(getRoomSchema({
    id,
    capacity,
    participants,
    availableParticipants,
    initialValues
  }))

  const watchedFields = new Array(capacity)
    .fill([])
    .map((p, index) => `room-${id}-participant-${index}`)

  const levelIcons = {
    SILVER: silver,
    GOLD: gold,
    DIAMOND: platinum,
    BRONZE: bronze
  }

  const handleFieldChange = (event) => {
    const { value, previousValue } = event.target
    const participantId = value || previousValue?.value
    const otherProps = value && previousValue?.value ? { previousParticipant: previousValue.value } : {}
    onChange({ participantId, roomId: id, intent: value ? 'add' : 'remove', ...otherProps })
  }

  const handleRandomFill = () => {
    onRandomFill({ id, capacity })
  }

  const handleMarkAsDone = (done) => {
    onDone({ id, full: done })
  }

  const handleOnRemove = () => {
    onRemove({ id })
  }

  const handleOnClean = () => {
    const actualOccupantsObject = getInitialValues()
    const actualOccupants = Object.values(actualOccupantsObject)
    onClean({ id, participantsToRemove: actualOccupants })
  }

  const toggleSeeAllDescription = () => {
    setSeeAllDescription(!seeAllDescription)
  }

  useEffect(() => {
    setAvailableParticipants(remainingParticipants)
  }, [remainingParticipants])

  useEffect(() => {
    setSchema(getRoomSchema({
      id,
      capacity,
      participants,
      availableParticipants,
      initialValues
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableParticipants])

  return (
    <Card className="room-card"
      sx={{
        minHeight: '200px',
        height: '100%',
        border: '1px solid #e2e2e2',
        '&:hover, &.selected': {
          '.room-card__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        }
      }}>
        { canRemove ? (
          <MDBox
            className="room-card__icon"
            sx={iconStyles}
            onClick={handleOnRemove}>
            <Icon fontSize="small">delete</Icon>
          </MDBox>
        ) : null }
        <Tooltip title="Remove all participants from the room">
          <MDBox
            className="room-card__icon"
            sx={{...iconStyles, ...(canRemove ? { right: '38px' } : { right: '5px' })}}
            onClick={handleOnClean}>
            <Icon fontSize="small">clear_all</Icon>
          </MDBox>
        </Tooltip>
      <MDBox
        sx={
          full
            ? {
                '&:after': {
                  content: "''",
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  borderRadius: '12px',
                  cursor: 'pointer'
                }
              }
            : {}
        }
        onClick={full ? () => handleMarkAsDone(false) : () => {}}>
        <MDBox p={1}>
          <MDBox display="flex" alignItems="flex-start">
            <MDAvatar
              src={levelIcons[level]}
              alt={title}
              size="lg"
              variant="rounded"
              bgColor="dark"
              sx={{
                p: 2,
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl
              }}
            />
            <MDBox display="flex" justifyContent="space-between" width="100%">
              <MDBox ml={2} lineHeight={0}>
                { accommodation && !!accommodation.name ? (
                  <MDTypography variant="caption">{accommodation.name}</MDTypography>
                ) : null }
                { !!title ? (
                  <MDTypography
                    variant="h6"
                    textTransform="capitalize"
                    fontWeight="medium">
                    {title}
                  </MDTypography>
                ) : null }
                { !!description ? (
                    <MDTypography
                      variant="body2"
                      sx={{ cursor: 'pointer' }}
                      onClick={toggleSeeAllDescription}
                      dangerouslySetInnerHTML={{ __html: seeAllDescription ? description : excerptHtml(description, 70, false) }}
                    />
                  ) : null }
              </MDBox>
              <MDBox>
                <RoomBeds category={category} />
              </MDBox>
            </MDBox>
          </MDBox>
          
          <MDBox display="flex" flexDirection="column" justifyContent="space-between">
            { isLoading ? (
              <MDBox mt={2}>
                <RoomSkeleton capacity={capacity} />
              </MDBox>
            ) : (
              <>
              <MDBox mt={2} key={Object.values(initialValues).join('-')}>
                <Form
                  {...schema}
                  values={initialValues}
                  watchedFields={watchedFields}
                  onFieldChange={handleFieldChange}
                />
              </MDBox>
              <MDBox
                display="flex"
                justifyContent={'flex-end'}
                gap={1}
                mt={2}
                mb={1}>
                {full ? (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50%"
                    color="white"
                    sx={{
                      p: 0.5,
                      backgroundColor: ({ palette: { success } }) => success.main
                    }}>
                    <Icon>check</Icon>
                  </MDBox>
                ) : (
                  <>
                    <Tooltip title="Fill this room randomly">
                      <IconButton
                        color="primary"
                        size="small"
                        sx={{ '&:hover': {
                          backgroundColor: 'grey.100',
                        } }}
                        onClick={handleRandomFill}>
                        <Icon fontSize="medium">auto_awesome</Icon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Mark as done">
                      <IconButton
                        color="success"
                        size="small"
                        sx={{ '&:hover': {
                          backgroundColor: 'grey.100',
                        } }}
                        onClick={() => handleMarkAsDone(true)}>
                        <Icon fontSize="medium">done</Icon>
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </MDBox>
              </>
            )}
          
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default RoomCard

