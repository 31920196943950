import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import { useSelector } from 'react-redux'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import OffsiteConfirmationDialog from 'components/Dialogs/OffsiteConfirmationDialog'
import { getBestFormat } from 'utils/image'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '12px',
  right: '12px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const OffsiteCard = ({
  id,
  title,
  status: offsiteStatus,
  welcome_text,
  start_at,
  end_at,
  indicative_number_days,
  href,
  venue,
  company,
  preference_form,
  showCompany,
  isPast = false,
  participation = {},
  context = 'offsite',
  dialogOpen = false,
  onParticipationChange,
  onDeleteOffsite,
  onCloneOffsite
}) => {
  const profile = useSelector((state) => state.auth.profile)
  const isStaffOrManager =
    profile.is_staff || profile.groups?.map((g) => g.name).includes('Manager')
  const { id: participantId, confirmation, replied_at } = participation
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(dialogOpen)
  const [initialStep, setInitialStep] = useState(0)
  const isConfirmed = confirmation !== null && replied_at
  const offsiteFormattedDates = indicative_number_days
    ? `${indicative_number_days} day${indicative_number_days > 1 ? 's' : ''}`
    : `${format(new Date(start_at), 'dd MMM')} - ${format(new Date(end_at), 'dd MMM')}`

  const handleConfirmationChange = (step) => {
    setInitialStep(step)
    setConfirmationDialogOpen(true)
  }

  const handlePreferencesSave = (values) => {
    const profilePreferencesMethod = profile.preferences ? 'patch' : 'post'
    onParticipationChange({ id, participantId, ...values, profilePreferencesMethod })
    setConfirmationDialogOpen(false)
  }

  const handleDeleteOffsite = () => {
    onDeleteOffsite(id)
  }

  const handleCloneOffsite = () => {
    if (onCloneOffsite && typeof onCloneOffsite === 'function') {
      onCloneOffsite(id)
    }
  }

  const closeConfirmationDialog = ({ refresh }) => {
    setConfirmationDialogOpen(false)
    onParticipationChange({ refresh })
  }

  const status = isConfirmed
    ? confirmation
      ? {
          title: 'Confirmed',
          color: 'success.main',
          icon: 'thumb_up_alt',
          confirmationDialog: true,
          actions: {
            primary: {
              color: 'primary',
              label: 'My preferences',
              icon: 'settings',
              onClick: () => handleConfirmationChange(1)
            },
            secondary: null
          }
        }
      : {
          title: 'Declined',
          color: 'primary.main',
          icon: 'thumb_down_alt',
          confirmationDialog: true,
          actions: {
            primary: null,
            secondary: null
          }
        }
    : {
        title: 'Pending',
        color: '#ffb900',
        icon: 'warning',
        confirmationDialog: true,
        actions: {
          primary: {
            label: 'Confirm your presence',
            color: 'warning',
            onClick: () => handleConfirmationChange(0)
          },
          secondary: null
        }
      }

  return (
    <Card
      className="offsite-card"
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
        height: '100%',
        maxHeight: '340px !important',
        '&:hover': {
          '.offsite-card__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        },
        ...(!indicative_number_days && isPast ? { opacity: 0.5 } : {})
      }}>
      <CardMedia
        component="img"
        src={!!venue.featured_image ? getBestFormat(venue.featured_image.formats, 'mobile') : ''}
        alt={title}
        sx={{
          margin: 0,
          minHeight: {
            xs: '120px',
            md: '100%'
          },
          maxHeight: {
            xs: '160px',
            md: '100%'
          },
          width: {
            xs: '100%',
            md: '33% !important'
          },
          minWidth: {
            xs: '100%',
            md: '33% !important'
          },
          objectFit: 'cover',
          borderRadius: {
            xs: '12px 12px 0 0',
            md: '12px 0 0 12px'
          },
          display: 'inline-block',
          position: 'relative'
        }}
      />
      <MDBox
        py={1}
        position="relative"
        sx={{
          width: {
            xs: '100%',
            md: '95% !important'
          },
          maxWidth: {
            xs: '100%',
            md: '95% !important'
          }
        }}
      >
        {participation.id ? (
          <MDBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              position: 'absolute',
              top: '16px',
              right: { xs: '16px', md: '24px' },
              color: status.color
            }}>
            <Icon>{status.icon}</Icon>
            <MDBox>
              <MDTypography variant="caption" display="block" textAlign="center">
                {status.title}
              </MDTypography>
              {isConfirmed &&
              (offsiteStatus ? !offsiteStatus.includes('CONFIRMED_PARTICIPANTS_LIST') : true) ? (
                <MDButton
                  size="small"
                  color="primary"
                  sx={{ minHeight: '24px', maxHeight: '24px', py: 0, px: 1.5 }}
                  onClick={() => handleConfirmationChange(0)}>
                  Change
                </MDButton>
              ) : null}
            </MDBox>
          </MDBox>
        ) : (
          <>
            {onCloneOffsite && typeof onCloneOffsite === 'function' ? (
              <MDBox
                className="offsite-card__icon"
                sx={{
                  ...iconStyles,
                  right: '84px',
                  backgroundColor: 'transparent',
                  color: 'primary.main',
                  border: '1px solid',
                  borderColor: 'primary.main'
                }}>
                <Icon fontSize="small" onClick={handleCloneOffsite}>
                  copy
                </Icon>
              </MDBox>
            ) : null}
            <MDBox
              className="offsite-card__icon"
              sx={{
                ...iconStyles,
                right: '48px'
              }}>
              <Link
                to={
                  href ||
                  `/dashboard/${context === 'offer' ? 'offers-offsites' : 'offsites'}/${id}/${
                    isStaffOrManager ? 'definition' : 'review'
                  }`
                }>
                <Icon fontSize="small" sx={{ color: 'white !important' }}>
                  edit
                </Icon>
              </Link>
            </MDBox>
            <MDBox className="offsite-card__icon" sx={iconStyles}>
              <Icon fontSize="small" onClick={handleDeleteOffsite}>
                delete
              </Icon>
            </MDBox>
          </>
        )}
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pr: 2,
            maxHeight: '340px !important',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#eee',
              borderRadius: '2px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#ccc',
              borderRadius: '2px'
            }
          }}>
          <MDTypography variant="h4" fontWeight="medium" maxWidth={{ xs: '75%', md: '83%' }}>
            {title}
          </MDTypography>
          <MDTypography
            variant="subtitle2"
            dangerouslySetInnerHTML={{ __html: welcome_text }}
            mb={3}
            sx={isConfirmed && confirmation ? { maxWidth: '83%' } : {}}
          />
          {showCompany && company ? (
            <MDTypography variant="button" display="flex" alignItems="center">
              <Icon sx={{ mr: 1 }}>business</Icon> {company?.name}
            </MDTypography>
          ) : null}
          <MDTypography variant="button" display="flex" alignItems="center" mt={1}>
            <Icon sx={{ mr: 1 }}>location_on</Icon> {venue?.name}
          </MDTypography>
          <MDTypography variant="button" display="flex" alignItems="center" mt={1}>
            <Icon sx={{ mr: 1 }}>event</Icon> {offsiteFormattedDates}
          </MDTypography>
          {participation.id ? (
            <MDBox
              pt={2}
              display={{ xs: 'block', md: 'flex' }}
              alignItems="flex-end"
              justifyContent={isConfirmed ? 'flex-start' : 'space-between'}
              flexGrow={1}
              sx={{ marginBottom: '-16px' }}>
              <Link
                to={
                  href ||
                  `/dashboard/${context === 'offer' ? 'offers-offsites' : 'offsites'}/${id}/${
                    isStaffOrManager ? 'definition' : 'review'
                  }`
                }
                target="_blank">
                <MDButton
                  variant="gradient"
                  color="secondary"
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    mr: { xs: 0, md: 1 },
                    mb: { xs: 1, md: 0 }
                  }}>
                  <Icon sx={{ mr: 1 }}>search</Icon> Offsite details
                </MDButton>
              </Link>
              {status.actions.primary ? (
                <MDButton
                  variant="gradient"
                  {...status.actions.primary}
                  sx={{ width: { xs: '100%', md: 'auto' } }}>
                  {status.actions.primary.icon ? (
                    <Icon sx={{ mr: 1 }}>{status.actions.primary.icon}</Icon>
                  ) : null}
                  {status.actions.primary.label}
                </MDButton>
              ) : null}
            </MDBox>
          ) : (
            <MDBox
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              flexGrow={1}
              sx={{ marginBottom: '-16px' }}>
              <Link
                to={
                  href ||
                  `/dashboard/${context === 'offer' ? 'offers-offsites' : 'offsites'}/${id}/${
                    isStaffOrManager ? 'definition' : 'review'
                  }`
                }>
                <MDButton variant="gradient" color="primary" size="small">
                  Details <Icon sx={{ ml: 1 }}>arrow_outward</Icon>
                </MDButton>
              </Link>
            </MDBox>
          )}
        </CardContent>
      </MDBox>
      {status.confirmationDialog ? (
        <OffsiteConfirmationDialog
          offsite={{
            id,
            title,
            venue,
            dates: offsiteFormattedDates,
            start_at,
            end_at,
            preference_form
          }}
          participation={participation}
          profile={profile}
          open={confirmationDialogOpen}
          initialStep={initialStep}
          setOpen={setConfirmationDialogOpen}
          onSave={handlePreferencesSave}
          onClose={closeConfirmationDialog}
        />
      ) : null}
    </Card>
  )
}

export default OffsiteCard
