import { Link, useLoaderData, useActionData, useSearchParams, Outlet } from 'react-router-dom'

import { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import JSConfetti from 'js-confetti'

import Card from '@mui/material/Card'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import StepLabel from '@mui/material/StepLabel'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import NotAuthorized from 'layouts/authentication/components/NotAuthorized'

import { capitalizeFirstLetter } from 'utils/functions'
import DefinitionStep from './steps/DefinitionStep'

const OffsiteDetailPage = ({ editingMode }) => {
  const actionData = useActionData()
  const [searchParams, setSearchParams] = useSearchParams()
  const [steps, setSteps] = useState([])
  const dispatch = useDispatch()
  const { offsite, unauthorized } = useLoaderData()
  const profile = useSelector((state) => state.auth.profile)

  const getSteps = (_profile) => ([
    {
      key: 'definition',
      label: 'Basic',
      title: 'Offsite informations',
      subtitle: 'Select the type of offsite you want to build',
      permission: 'manager'
    },
    {
      key: 'participants',
      label: 'Participants',
      description: 'Select the company you want to build a offsite for',
      permission: 'manager'
    },
    {
      key: 'agenda',
      label: 'Agenda',
      title: 'Offsite agenda',
      subtitle: 'Select the activities of your offsite',
      permission: 'manager'
    },
    {
      key: 'accommodations',
      label: 'Accommodations',
      title: 'Accommodations',
      offsite,
      permission: 'manager'
    },
    {
      key: 'review',
      label: 'Review',
      description: 'Confirm the informations of your offsite'
    },
    {
      key: 'communication',
      label: 'Communication',
      title: 'Details & Communication',
      subtitle: '',
      permission: 'manager'
    }
  ].filter((s) => {
    const isOffsiteManager = _profile.offsites && _profile.offsites.length && _profile.offsites.some(o => o.your_role !== 'PARTICIPANT')
    return s.permission && s.permission === 'manager' ? (_profile.is_staff || _profile.groups?.map(g => g.name).includes('Manager') || isOffsiteManager) : true
  }))

  useEffect(() => {
    if (actionData?.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'There was an error',
          message: actionData?.fullError,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if ((searchParams && searchParams.get('new')) || actionData?.success) {
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `${capitalizeFirstLetter(actionData?.entity) || 'Offsite'} saved successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
      setSearchParams({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, actionData, dispatch])

  useEffect(() => {
    let jsConfetti
    if (searchParams && searchParams.get('contract-signed')) {
      jsConfetti = new JSConfetti()
      jsConfetti.addConfetti({
        confettiNumber: 200,
        confettiRadius: 4
      })
      setTimeout(() => {
        jsConfetti.addConfetti({
          confettiNumber: 200,
          confettiRadius: 4
        })
      }, 1000)
      setTimeout(() => {
        jsConfetti.addConfetti({
          confettiNumber: 200,
          confettiRadius: 4
        })
      }, 2300)
      setSearchParams({})
    }
    return () => {
      if (jsConfetti) {
        jsConfetti.clearCanvas()
      }
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    if (profile && !!profile.groups) {
      setSteps(getSteps(profile))
    }
  }, [profile])

  return (
    <>
      <MDBox py={3} mb={{ xs: 10, md: 20 }} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%', mt: { xs: 2, md: 8 } }}>
          {unauthorized ? (
            <NotAuthorized />
          ) : (
            <Grid item xs={12} lg={12}>
              <Card sx={{ height: '100%', mb: { xs: 12, md: 4 } }}>
                {editingMode ? (
                  <MDBox mx={2} mt={-3}>
                    <Stepper
                      sx={{
                        p: 3,
                        color: 'white !important',
                        background: 'linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))',
                        '*': {
                          stroke: 'white !important',
                          fill: 'white !important',
                          color: 'white !important'
                        }
                      }}
                      alternativeLabel>
                      {steps.map(({ label, key }, index) => (
                        <Step key={`${label}-${index}`} disabled={!editingMode}>
                          {editingMode ? (
                            <Link to={`${key}`}>
                              <StepButton>
                                <StepLabel>{label}</StepLabel>
                              </StepButton>
                            </Link>
                          ) : (
                            <StepButton>
                              <StepLabel>{label}</StepLabel>
                            </StepButton>
                          )}
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                ) : null}

                <MDBox p={{ xs: 2, lg: 3 }}>
                  {editingMode ? (
                    <Outlet />
                  ) : (
                    <DefinitionStep
                      editingMode={false}
                      title="Create your offsite!"
                      offsite={offsite}
                      profile={profile}
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </>
  )
}

export default OffsiteDetailPage
