import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import { resolveFullOffsites } from 'utils/offsites'
import OffsiteCard from 'layouts/pages/account/profile/components/OffsiteCard'
import OffsitesTableView from './OffsitesTableView'
import Pagination from 'components/Pagination'

import { CardsSkeleton } from './Skeletons'

import noResults from 'assets/illustrations/noresults.svg'

const OffsitesList = ({ offsites, pagination, searchParams, view, onClone, onDelete }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [fullOffsites, setFullOffsites] = useState(null)
  const isStaff = useSelector((state) => state.auth.profile.is_staff)

  useEffect(() => {
    const checkOffsitesRefresh = (offsites, fullOffsites) => {
      if (!fullOffsites || !Array.isArray(fullOffsites)) return true
      return (
        fullOffsites.length !== offsites.length ||
        fullOffsites.some((offsite) => {
          return !offsites.map(({ id }) => id).includes(offsite.id)
        })
      )
    }
    const resolveOffsites = async () => {
      try {
        const needRefresh = checkOffsitesRefresh(offsites, fullOffsites)
        if (needRefresh) {
          const results = await resolveFullOffsites({ offsites, dispatch })
          setFullOffsites(results)
        }
      } catch (error) {
        console.warn(error)
      } finally {
        setIsLoading(false)
      }
    }
    resolveOffsites()
    return () => {}
  }, [offsites])

  if (isLoading) {
    return <CardsSkeleton />
  }
  return view === 'grid' ? (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          height: '100%',
          mt: 2
        }}>
        {fullOffsites && fullOffsites.length ? (
          fullOffsites.map((result, index) => {
            return (
              <Grid key={`${index}-${result.id}`} item xs={12} md={6}>
                <OffsiteCard
                  {...result}
                  showCompany={isStaff}
                  onDeleteOffsite={onDelete}
                  onCloneOffsite={onClone}
                />
              </Grid>
            )
          })
        ) : (
          <Grid item xs={12}>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="50vh">
              <MDBox
                component="img"
                src={noResults}
                alt="no results"
                width="100%"
                maxHeight={200}
                mb={3}
              />
              <MDTypography variant="h5">No results found!</MDTypography>
            </MDBox>
          </Grid>
        )}
      </Grid>
      {pagination && pagination.lastPage > 1 && !!fullOffsites.length ? (
        <Grid container justifyContent="center" marginTop={6}>
          <Pagination
            page={pagination.page || 1}
            lastPage={pagination.lastPage}
            searchParams={searchParams}
          />
        </Grid>
      ) : null}
    </>
  ) : (
    <MDBox mt={2}>
      <OffsitesTableView
        offsites={fullOffsites}
        onDelete={onDelete}
        pagination={pagination}
        hideColumns={isStaff ? [] : ['company']}
      />
    </MDBox>
  )
}

export default OffsitesList
