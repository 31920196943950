import { Suspense, useState, useEffect, useCallback } from 'react'

import { Await, Link, useSubmit, useLoaderData, useActionData } from 'react-router-dom'

import { format } from 'date-fns'

import { smartwayApi } from 'services/api'
import { useDispatch, useSelector } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import { saveParticipantPreferences } from 'utils/offsites'

// @mui material components
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'

// Profile page components
import Sidenav from 'layouts/pages/account/profile/components/Sidenav'
import Header from 'layouts/pages/account/profile/components/Header'
import BasicInfo from 'layouts/pages/account/profile/components/BasicInfo'
import ChangePassword from 'layouts/pages/account/profile/components/ChangePassword'
import DeleteAccount from 'layouts/pages/account/profile/components/DeleteAccount'
import CompanyWall from 'layouts/pages/account/profile/components/CompanyWall'

import { ProfilePageSkeleton } from './Skeletons'
import GenericError from 'components/GenericError.js/GenericError'
import OffsiteCardsWrapper from './components/OffsiteCard/OffsiteCardsWrapper'

const Profile = ({ context = 'profile' }) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [updatedProfile, setUpdatedProfile] = useState(null)
  const dispatch = useDispatch()
  const submit = useSubmit()
  const { profile } = useLoaderData()
  const myProfile = useSelector((state) => state.auth.profile)
  const actionData = useActionData()

  let sidenavItems = [
    { icon: 'person', label: 'profile', href: 'profile' },
    { icon: 'receipt_long', label: 'basic info', href: 'basic-info' },
    { icon: 'lock', label: 'change password', href: 'change-password' },
    { icon: 'delete', label: 'delete account', href: 'delete-account' }
  ]

  if (context && context === 'user') {
    sidenavItems = sidenavItems.filter((i) => i.href !== 'change-password')
  }

  const handleNotification = useCallback(
    (data) => {
      const notification = {
        'refresh-data': {
          success: 'Profile updated successfully',
          error: 'Failed to update profile'
        },
        'change-password': {
          success: 'Password changed successfully',
          error: 'Failed to change password'
        },
        'basic-info': {
          success: 'Profile updated successfully',
          error: 'Failed to update profile'
        },
        avatar: {
          success: 'Avatar updated successfully',
          error: 'Failed to update avatar'
        },
        'offsite-participation-change': {
          success: 'Change confirmed successfully',
          error: 'Failed to change the status of your participation'
        }
      }
      const { success, error, intent } = data
      if (success) {
        dispatch(
          notify({
            id: intent,
            icon: 'done',
            title: 'Well done!',
            message: notification[intent].success,
            type: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          })
        )
      }
      if (error) {
        dispatch(
          notify({
            id: intent,
            icon: 'error',
            title: 'Ops!',
            message: notification[intent].error,
            type: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          })
        )
      }
    },
    [dispatch]
  )

  const handleChangePassword = (values) => {
    submit(
      { intent: 'change-password', ...values },
      { method: 'post', encType: 'application/json' }
    )
  }

  const handleAvatarChange = async (values) => {
    if (context === 'user') return

    const formData = new FormData()
    if (values.avatar) {
      formData.append('avatar', values.avatar)
    }
    try {
      const response = await dispatch(
        smartwayApi.endpoints.updateEntity.initiate({
          entity: context,
          formData,
          bodyType: 'formData',
          id: 'me/avatar'
        })
      )
      if (response?.data) {
        handleNotification({ intent: 'avatar', success: true })
        setUpdatedProfile({ ...response.data, ...response.data.profile })
      } else {
        handleNotification({ intent: 'avatar', error: true })
      }
    } catch (error) {
      handleNotification({ intent: 'avatar', error: true })
    }
  }

  const handleBasicInfo = async (values) => {
    const payload = {}
    const excluded = [
      'permissions',
      'id',
      'is_staff',
      'is_active',
      'is_superuser',
      'avatarChanged',
      'avatar_formats',
      'offsites',
      'isFull',
      'firebase_id',
      'profile',
      'profile_id',
      'last_login',
      'user_id',
      'created_by_smartway'
    ]
    for (let [key, value] of Object.entries(values)) {
      if (!excluded.includes(key)) {
        if (key === 'groups' && Array.isArray(value) && typeof value[0] === 'object') {
          payload[key] = value.map((group) => group.id)
        } else if (key === 'birthdate' && value) {
          payload[key] = format(value, 'yyyy-MM-dd')
        } else {
          payload[key] = value && value.id ? value.id : value
        }
      }
    }
    const response = await dispatch(
      smartwayApi.endpoints.updateEntity.initiate({
        entity: context,
        ...payload,
        id: context === 'user' ? profile.id : 'me'
      })
    )
    if (response?.data) {
      handleNotification({ intent: 'basic-info', success: true })
      setUpdatedProfile({ ...response.data, ...response.data.profile })
    } else {
      handleNotification({ intent: 'basic-info', error: true })
    }

    // submit({ intent: 'basic-info', ...payload, ...( context === 'user' ? { id: values.id } : {}) }, { method: 'patch', encType: 'application/json' })
  }

  const handleOffsiteParticipationChange = async (values) => {
    // Workaround to refresh offsite card when confirmation changes
    if (values.hasOwnProperty('refresh')) {
      if (values.refresh) {
        submit({ intent: 'refresh-data' }, { method: 'post', encType: 'application/json' })
      }
      return false
    }
    try {
      const responses = await saveParticipantPreferences({
        dispatch,
        payload: values,
        context: 'profile'
      })
      if (responses.every((r) => !!r.data)) {
        submit({ intent: 'refresh-data' }, { method: 'post', encType: 'application/json' })
      } else {
        throw new Error('Failed to change the status of your participation')
      }
    } catch (error) {
      handleNotification({ intent: 'offsite-participation-change', error: true })
    }
  }

  const openDeleteConfirmation = () => setDeleteConfirmationOpen(true)
  const closeDeleteConfirmation = () => setDeleteConfirmationOpen(false)

  const getOffsiteLink = (offsiteId) => {
    if (myProfile.permissions.includes('change_offsite')) {
      return `/dashboard/offsites/${offsiteId}/definition`
    }
    const offsiteUrl = btoa(`{"offer":${offsiteId}}`)
    return `https://listings.smartway.work/offsites/${offsiteUrl}`
  }

  const onDelete = () => {
    submit({ intent: 'delete', id: profile.id }, { method: 'delete', encType: 'application/json' })
  }

  useEffect(() => {
    if (!!actionData) {
      handleNotification(actionData)
    }
  }, [actionData, dispatch, handleNotification])

  return (
    <>
      <MDBox mt={{ xs: 2, md: 4 }}>
        {context && context === 'user' ? (
          <MDBox>
            <Link to="/dashboard/users">
              <MDTypography
                variant="button"
                fontWeight="bold"
                color="dark"
                display="flex"
                alignItems="center"
                mb={3}>
                <Icon sx={{ mr: 1 }}>arrow_back</Icon>
                Back to users
              </MDTypography>
            </Link>
          </MDBox>
        ) : null}
        <Suspense fallback={<ProfilePageSkeleton />}>
          <Await resolve={profile} errorElement={<GenericError minHeight="calc(100vh - 200px)" />}>
            {(profile) => {
              if (profile && profile.offsites && !!profile.offsites.length) {
                sidenavItems.splice(1, 0, {
                  icon: 'event',
                  label: 'offsite participation',
                  href: 'offsite-participation'
                })
              }
              const offsites =
                profile.offsites && profile.offsites.length
                  ? profile.offsites
                      // TODO
                      // Find a new visualisation for managers / admins / town angels 
                      .filter((o) => !!o.participation)
                      .sort(
                        (a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
                      )
                      .map((o) => {
                        return {
                          ...o,
                          venue: o.all_in_venue || o.town,
                          venueType: o.all_in_venue ? 'allInVenue' : 'town',
                          url: getOffsiteLink(o.id),
                          needConfirmation: o.participation?.confirmation === null
                        }
                      })
                  : []
              const firstNeedConfirmationOffsite =
                context === 'profile' && offsites.find((o) => o.needConfirmation)
              return (
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Sidenav items={sidenavItems} />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <MDBox mb={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Header
                            profile={updatedProfile || profile}
                            onChange={handleBasicInfo}
                            onAvatarChange={handleAvatarChange}
                          />
                        </Grid>
                        <CompanyWall context={context} profile={updatedProfile || profile}>
                          <Grid item xs={12}>
                            {offsites && !!offsites.length ? (
                              <MDBox id="offsite-participation">
                                <MDTypography
                                  variant="h5"
                                  fontWeight="medium"
                                  display="flex"
                                  alignItems="center">
                                  <Icon sx={{ mr: 1 }}>event</Icon>
                                  Your next event{!offsites.length ? null : 's'}
                                </MDTypography>
                                <OffsiteCardsWrapper
                                  offsites={offsites}
                                  dispatch={dispatch}
                                  needOffsiteData
                                  firstNeedConfirmationOffsite={firstNeedConfirmationOffsite}
                                  onParticipationChange={handleOffsiteParticipationChange}
                                />
                              </MDBox>
                            ) : null}
                          </Grid>
                          <Grid item xs={12}>
                            <BasicInfo
                              profile={updatedProfile || profile}
                              onSubmit={handleBasicInfo}
                            />
                          </Grid>
                          {context && context === 'user' ? null : (
                            <Grid item xs={12}>
                              <ChangePassword onSubmit={handleChangePassword} />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <DeleteAccount onDelete={openDeleteConfirmation} />
                          </Grid>
                        </CompanyWall>
                      </Grid>
                    </MDBox>
                  </Grid>
                </Grid>
              )
            }}
          </Await>
        </Suspense>
      </MDBox>
      <ConfirmationDialog
        title="Are you sure you want to delete your profile?"
        message="This action is irreversible and you will not be able to access this dashboard anymore."
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        onConfirm={onDelete}
        onCancel={closeDeleteConfirmation}
      />
    </>
  )
}

export default Profile
